import {
  Button,
  Card,
  DatePicker,
  Flex,
  Form,
  Input,
  notification,
  Select,
  Switch,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Server } from '../../../../../../api/server-index';
import { AuthContext } from '../../../../../../context/auth-context';
import { Location } from '../../../../../../types/enum/location';
import BackButton from '../../../../../common/buttons/back-button.component';
import Subtitle from '../../../../../common/titles/subtitle.component';
import { formItemLayout } from '../../../../form/form-item.layout';

interface SelectOptions {
  label: string;
  value: string;
}

const NewEvent = () => {
  const { t } = useTranslation(['events', 'common']);
  const { currentAcademicYear } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [subjectOptions, setSubjectOptions] = useState<SelectOptions[]>([]);

  const fetchSubjectOptions = useCallback(async () => {
    const response = await Server.Subjects.getSubjectsForLecturer(
      currentAcademicYear
    );

    const options = response?.map((subject) => {
      const subjectName = subject.subjectName;
      const subjectNameEn = subject.subjectNameEn
        ? `(${subject.subjectNameEn})`
        : '';
      return {
        value: subject.id,
        label: `${subjectName} ${subjectNameEn}`,
      };
    });

    setSubjectOptions(options);
  }, [currentAcademicYear]);

  useEffect(() => {
    fetchSubjectOptions();
  }, [fetchSubjectOptions]);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const response = await Server.Events.createEvent({
      subjectName: values.subjectName,
      dateStart: values.dateStart.toISOString(),
      dateEnd: values.dateEnd.toISOString(),
      notes: values.notes,
      classroom: values.classroom,
      subject: values.subject,
      location: values.location,
      international: values.international,
    });

    if (response.status === 201) {
      notification.success({
        message: t('create_update_event.create_success'),
      });
      navigate('/events');
    }

    setLoading(false);
  };

  return (
    <Card className='mb-2 shadow-sm'>
      <BackButton href={`/events${location?.search}`} />
      <Subtitle text={t('create_update_event.create_title')} />
      <Form
        name='edit-event'
        className='mt-2'
        onFinish={handleSubmit}
        {...formItemLayout}
      >
        <Form.Item
          label={t('create_update_event.form.subject_name')}
          name='subjectName'
          rules={[
            { required: true, message: t('create_update_event.form.required') },
          ]}
          validateTrigger='onBlur'
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('create_update_event.form.start')}
          name='dateStart'
          rules={[
            {
              required: true,
              message: t('create_update_event.form.required'),
            },
          ]}
          validateTrigger='onBlur'
        >
          <DatePicker
            showTime
            format={'DD.MM.YYYY. HH:mm'}
            placeholder=''
            className='w-100'
          />
        </Form.Item>
        <Form.Item
          label={t('create_update_event.form.end')}
          name='dateEnd'
          rules={[
            {
              required: true,
              message: t('create_update_event.form.required'),
            },
          ]}
          validateTrigger='onBlur'
        >
          <DatePicker
            showTime
            format={'DD.MM.YYYY. HH:mm'}
            placeholder=''
            className='w-100'
          />
        </Form.Item>
        <Form.Item
          label={t('create_update_event.form.subject')}
          name={'subject'}
        >
          <Select options={subjectOptions} />
        </Form.Item>
        <Form.Item
          label={t('create_update_event.form.classroom')}
          name='classroom'
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('create_update_event.form.required'),
            },
          ]}
          label={t('create_update_event.form.location.title')}
          name={'location'}
        >
          <Select
            showSearch
            options={Object.values(Location).map((option) => ({
              label: t(`create_update_event.form.location.${option}`),
              value: option,
            }))}
          />
        </Form.Item>
        <Form.Item label={t('create_update_event.form.notes')} name='notes'>
          <TextArea maxLength={240} showCount />
        </Form.Item>
        <Form.Item
          label={t('create_update_event.form.intl')}
          name='international'
        >
          <Switch />
        </Form.Item>
        <Flex justify='flex-end' className='mt-2'>
          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary' ghost>
              {t('create_update_event.form.submit')}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Card>
  );
};

export default NewEvent;
