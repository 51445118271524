import { InfoCircleFilled } from '@ant-design/icons';
import { Descriptions, Flex, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../../../../../context/auth-context';
import { EventDto } from '../../../../../../types/dto/event.dto';

interface EventDetailsProps {
  event: EventDto;
  additionalInfo: boolean;
}

const EventDetails = ({ event, additionalInfo }: EventDetailsProps) => {
  const { t } = useTranslation(['events']);
  const { language } = useContext(AuthContext);

  const renderMoodleAlert = (numberOfMoodleCourses: number) => {
    switch (numberOfMoodleCourses) {
      case 0:
        return (
          <Tooltip title={t('event_list.moodleAlert0')}>
            <InfoCircleFilled style={{ color: '#ff4d4f' }} />
          </Tooltip>
        );

      case 1:
        return (
          <Tooltip title={t('event_list.moodleAlert1')}>
            <InfoCircleFilled style={{ color: '#faad14' }} />
          </Tooltip>
        );

      default:
        return (
          <Tooltip
            title={t('event_list.moodleAlertMultiple', {
              number: numberOfMoodleCourses,
            })}
          >
            <InfoCircleFilled style={{ color: '#52c41a' }} />
          </Tooltip>
        );
    }
  };

  return (
    <Descriptions
      column={1}
      bordered
      size='small'
      title={
        <Flex vertical>
          <h4 className='m-0'>{event?.subjectName}</h4>
          {event.international ? (
            <i className='text-gray' style={{ fontSize: 14 }}>
              {t('create_update_event.form.intl')}
            </i>
          ) : (
            <></>
          )}
        </Flex>
      }
      className='mt-1'
      labelStyle={{ width: '50%' }}
    >
      <Descriptions.Item label={t('event_list.date_and_time')}>
        {dayjs(event?.dateStart).format('DD.MM.YYYY. HH:mm')} -{' '}
        {dayjs(event?.dateEnd).format('HH:mm')}
      </Descriptions.Item>
      <Descriptions.Item label={t('create_update_event.form.subject')}>
        {event.subjects?.length ? (
          <>
            {event.subjects?.map((subject, index) => (
              <Flex key={index} gap={6}>
                <span>
                  {language === 'en'
                    ? subject.subjectNameEn ?? subject.subjectName
                    : subject.subjectName}
                </span>
                {renderMoodleAlert(subject.numberOfMoodleCourses!)}
              </Flex>
            ))}
          </>
        ) : (
          <i>{t('event_list.no_subjects')}</i>
        )}
      </Descriptions.Item>
      <Descriptions.Item label={t('event_list.lecturers')}>
        {event.lecturers?.length ? (
          <>
            {event.lecturers.map((lecturer, index) => {
              const isLast = index === event.lecturers!.length - 1;
              return (
                <span key={lecturer.pin}>
                  {lecturer.givenName + ' ' + lecturer.familyName}
                  <span>{!isLast ? ', ' : ''}</span>
                </span>
              );
            })}
          </>
        ) : (
          <i>{t('event_list.no_lecturers')}</i>
        )}
      </Descriptions.Item>
      {event.classroom && additionalInfo ? (
        <Descriptions.Item label={t('event_list.classroom')}>
          {event.classroom}
        </Descriptions.Item>
      ) : (
        <></>
      )}
      {event.location ? (
        <Descriptions.Item label={t('create_update_event.form.location.title')}>
          {t(`create_update_event.form.location.${event.location}`)}
        </Descriptions.Item>
      ) : (
        <></>
      )}
    </Descriptions>
  );
};

export default EventDetails;
